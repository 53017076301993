<script setup>
defineProps(["categorie"]);
</script>

<template>
    <div class="relative inline-block md:w-64 md:h-64 md:rounded-3xl w-full max-md:h-40 hover:cursor-pointer max-md:overflow-hidden" @click="goto()">
        <img :src="'/public/storage/categorieen/' + categorie.naam.toLowerCase() + '.jpg'" :alt="categorie.naam" class="md:rounded-xl md:w-full md:h-full">
        <div class="overlay md:rounded-3xl">
            <p>{{categorie.naam.toUpperCase()}}</p>
        </div>
    </div>

</template>

<script>
export default {
    name: "CategorieCard",
    methods: {
        goto: function() {
            this.$inertia.visit('/projecten/categorie/' + this.categorie.id);
        }
    }
}
</script>

<style scoped>

.overlay {
    @apply bg-donkerblauw;
    @apply bg-opacity-60;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.overlay p {
    @apply text-white;
    @apply font-bold;
    @apply text-3xl;
    @apply text-center;
    @apply self-center;
    @apply opacity-100;
    @apply mt-3;
}

</style>
