<template>
    <div class="bg-donkerblauw flex flex-col md:flex-row">
        <div class="relative inline-block trapezium">
            <img src="/public/storage/algemeen/overons.jpg" alt="Over ons"/>
            <div class="overlay"></div>
        </div>
        <div class="flex flex-col justify-center items-center py-10">
            <h2 class="text-center">Roblossing, voor alle oplossingen in en rond uw huis!</h2>
            <p class="md:w-4/6 mx-8 text-center">Iedereen kent die kleine klusjes wel die al jaren worden uitgesteld. Dit kan gaan van een piepend scharniertje tot het haagje in de voortuin tot de druppende kraan, voor al deze problemen vinden wij een oplossing.  Maar ook voor de grote klussen bieden wij oplossingen. Zoals die grote boom in de tuin waar geen beginnen meer aan is of die oude afsluiting waardoor de hond altijd ontsnapt. Niet alleen de problemen buitenshuis maar ook binnen vinden wij de gepaste oplossingen. Zo kunt u beroep op ons doen voor een uitbreiding/nieuwe centrale verwarming of een aanpassing van uw elektriciteitskast.</p>
            <primary-button class="mt-8 md:w-2/6 w-10/12 text-md" @click="contact()">Neem contact op</primary-button>
        </div>

    </div>
</template>

<script>
import PrimaryButton from "@/Components/PrimaryButton.vue";

export default {
    name: "OverOnsBalk",
    methods: {
        contact() {
            this.$inertia.visit('/contact');
        }
    },
    components: {PrimaryButton}
}
</script>

<style scoped>

* {
    @apply text-white;
}

h2 {
    @apply text-3xl;
    @apply font-bold;
    @apply pb-2
}

.overlay {
    @apply bg-donkerblauw;
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-full;
    @apply opacity-75;
}

@media screen and (min-width: 768px){
    .trapezium {
        clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
    }
}



</style>
