<script setup>

import CategorieCard from "@/Components/Home/CategorieCard.vue";

defineProps(["categorieen"]);
</script>

<template>
    <div class="py-8">
        <div class="max-w-7xl mx-auto">
            <ul class="flex flex-col md:flex-row justify-around md:gap-4">
                <li v-for="categorie in categorieen" :key="categorie.id" class="">
                    <CategorieCard :categorie="categorie"/>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Categoriebalk",
}
</script>

<style scoped>

</style>
